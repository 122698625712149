import { makeAutoObservable } from "mobx";
import { PAYMENT_STATUS_UNPAID, USER_DATA_KEY } from "../../utils/constants";
import {
  GetOrdersParam,
  OrderResult,
  OrderResponse,
} from "../../utils/interfaces";
import { mapToCartItem } from "../cart-store/cart-store.model";
import { ProductUnit } from "../product-store/product-store.model";
import RootStore from "../root-store";
import { mapOrderResponseToOrder, Order } from "./order-store.model";
import LIDOBypassAreaIds from "../../public/bypass-area-ids.json";
import qaPhoneNumbers from "../../public/qa-automation-phone-numbers.json";
import { storageLoad } from "../../utils/storage";
import { UserData } from "../account-store/account-store.model";

class OrderStore {
  rootStore: RootStore;
  order: Order = null;
  orders: Map<string, Order> = new Map<string, Order>();
  ordersCount = 0;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this, { rootStore: false });
  }

  get allOrders() {
    return Array.from(this.orders.values());
  }

  setOrder(_order: Order) {
    this.order = _order;
  }

  setOrders(_orders: Map<string, Order>) {
    this.orders = _orders;
  }

  setOrdersCount(count: number) {
    this.ordersCount = count;
  }

  async getOrders(params: GetOrdersParam): Promise<OrderResult> {
    const result: OrderResult = await this.rootStore.api.getOrders(params);
    if (result) {
      const { rows, count } = result;
      this.setOrdersCount(count);
      if (params.offset > 0) {
        const _orders = this.orders;
        rows.forEach((order: OrderResponse) => {
          _orders.set(order.id, mapOrderResponseToOrder(order));
        });
        this.setOrders(_orders);
      } else {
        const _orders = new Map<string, Order>();
        rows.forEach((order: OrderResponse) => {
          _orders.set(order.id, mapOrderResponseToOrder(order));
        });
        this.setOrders(_orders);
      }
    }
    return result;
  }

  async getOrderDetail(trxNumber: string): Promise<Order> {
    const response: OrderResponse = await this.rootStore.api.getOrderDetail(
      trxNumber
    );
    const order = response ? mapOrderResponseToOrder(response) : null;
    this.setOrder(order);
    return order;
  }

  async repeatOrder(order: Order): Promise<string[]> {
    try {
      // const productIds = [];
      // const resellerProductIds = [];

      // order.orderItems.forEach(({ productId, product }) => {
      //   const { referenceProductId } = product;
      //   if (referenceProductId) resellerProductIds.push(productId);
      //   else productIds.push(productId);
      // })
      // if (!productIds.length && !resellerProductIds.length) return []

      const isResellerOrder =
        order.sellerDetail.username === process.env.NEXT_PUBLIC_RESELLER_PATH;
      if (
        order.sellerDetail.username !==
        this.rootStore.sellerStore.sellerProfile?.User?.username
      ) {
        // change seller
        await this.rootStore.sellerStore.getSellerProfile({
          username: order.sellerDetail.username,
        });
        // re-init cart
        if (isResellerOrder) {
          await this.rootStore.coverageAreaStore.getAndSetCoverageArea({
            coverageAreaId: order.coverageArea?.id,
            locationSource: "DROPDOWN",
          });
        }
        await this.rootStore.cartStore.initCart();
      } else {
        if (isResellerOrder && order.coverageArea) {
          const selectedCoverageAreaId =
            this.rootStore.coverageAreaStore.selectedCoverageArea?.id;
          if (selectedCoverageAreaId != order.coverageArea?.id) {
            await this.rootStore.coverageAreaStore.getAndSetCoverageArea({
              coverageAreaId: order.coverageArea?.id,
              locationSource: "DROPDOWN",
            });
          }
          await this.rootStore.cartStore.initCart();
        }
      }

      order.orderItems.forEach((orderItem) => {
        // placeholder product unit, product availability checking will be on cart module
        const productUnit: ProductUnit = {
          id: null,
          key: null,
          price: orderItem.price,
          basePrice: null,
          discountPrice: orderItem.discountPrice,
          productId: orderItem.productId,
          unit: orderItem.unit,
          ratio: null,
          active: null,
          availableOnline: null,
          minQty: null,
          userUnitId: null,
          wholesalePrice: null,
          deletedAt: null,
          createdAt: null,
          updatedAt: null,
          featured: false,
          weight: null,
          width: null,
          height: null,
          length: null,
        };
        this.rootStore.cartStore.addItemToCart(
          mapToCartItem(orderItem.product, productUnit, orderItem.qty)
        );
      });

      // // check products availability (does not mean the unit is available, will be checked after)
      // const checkedProductsMap = new Map<string, Product>()
      // // seller products
      // if (productIds.length) {
      //   const response = await this.rootStore.productStore.getProducts({
      //     username: order.sellerDetail.username,
      //     id: productIds.toString(),
      //     limit: productIds.length,
      //   }, false)
      //   if (response?.product && Array.isArray(response?.product?.rows)) {
      //     response?.product?.rows.forEach((product) => checkedProductsMap[product.id] = mapProductResponseToProduct(product))
      //   }
      // }
      // // reseller products
      // if (resellerProductIds.length) {
      //   const coverageAreaId = order.coverageArea.id;
      //   if (coverageAreaId) {
      //     const response = await this.rootStore.productStore.getResellerProducts({
      //       username: order.sellerDetail.username,
      //       id: resellerProductIds.toString(),
      //       limit: resellerProductIds.length,
      //       coverage_area_id: coverageAreaId,
      //     }, false)
      //     if (response?.product && Array.isArray(response?.product?.rows)) {
      //       response?.product?.rows.forEach((product) => checkedProductsMap[product.id] = mapProductResponseToProduct(product))
      //     }
      //   }
      // }

      // // add to cart available products with spesific unit and note unvailable products for the info message
      // const unavailableProducts: string[] = [];
      // order.orderItems.forEach((orderItem) => {
      //   const productFoundOnServer: Product = checkedProductsMap[orderItem.productId];
      //   const unitFromServer = productFoundOnServer && productFoundOnServer.productUnits.find((product) => {
      //     return (product.unit.toLocaleLowerCase() === orderItem.unit.toLocaleLowerCase()) && (product.minQty <= orderItem.qty)
      //   })
      //   // if product is available
      //   if (productFoundOnServer) {
      //     // if unit is available
      //     if (unitFromServer) {
      //       this.rootStore.cartStore.addItemToCart(mapToCartItem(productFoundOnServer, unitFromServer, orderItem.qty))
      //     } else {
      //       unavailableProducts.push(orderItem.product.name + ' - satuan: ' + orderItem.unit);
      //     }
      //   } else {
      //     // placeholder unavailable product unit
      //     const productUnit: ProductUnit = {
      //       id: null,
      //       key: null,
      //       price: orderItem.price,
      //       basePrice: null,
      //       discountPrice: orderItem.discountPrice,
      //       productId: orderItem.productId,
      //       unit: orderItem.unit,
      //       ratio: null,
      //       active: null,
      //       availableOnline: null,
      //       minQty: null,
      //       userUnitId: null,
      //       wholesalePrice: null,
      //       deletedAt: null,
      //       createdAt: null,
      //       updatedAt: null,
      //     }
      //     this.rootStore.cartStore.addItemToCart(mapToCartItem(orderItem.product, productUnit, orderItem.qty))
      //     unavailableProducts.push(orderItem.product.name)
      //   }
      // })

      // return unavailableProducts;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async markOrderLogsAsRead(orderId: string) {
    await this.rootStore.api.markOrderLogsAsRead(orderId);
  }

  async checkUnpaidOrder(): Promise<boolean> {
    let hasUnpaidOrder = false;

    // check bypass area ids
    const selectedCoverageArea =
      this.rootStore.coverageAreaStore.selectedCoverageArea;
    if (LIDOBypassAreaIds?.includes(selectedCoverageArea?.id)) {
      hasUnpaidOrder = false;
      return;
    }

    // check bypass QA automation phone numbers
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "production") {
      const savedUser: UserData = await storageLoad(USER_DATA_KEY);
      if (qaPhoneNumbers?.includes(savedUser?.phone)) {
        hasUnpaidOrder = false;
        return;
      }
    }

    const orders = await this.getOrders({
      limit: 1,
      offset: 0,
      reseller: true,
      status: "created,accepted,shipped,on_delivery,completed",
    });
    if (orders?.count > 0) {
      const lastOrder = await this.getOrderDetail(
        orders?.rows[0]?.transaction_number
      );
      if (lastOrder) {
        if (lastOrder?.paymentStatus === PAYMENT_STATUS_UNPAID) {
          hasUnpaidOrder = true;
        }
      }
    }

    return hasUnpaidOrder;
  }
}

export default OrderStore;
