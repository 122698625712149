import { cast, Instance, types } from "mobx-state-tree";
import { AdminFeeType } from "../../utils/enum";
import {
  SellerCategoryResult,
  GetSellerProfileResult,
  AdminFeeResponse,
  DeliveryFeeObject,
} from "../../utils/interfaces";

/**
 * SELLER CATEGORY MODEL
 */

export const SellerCategoryModel = types.model("SellerCategoryModel").props({
  id: types.string,
  name: types.string,
  icon: types.maybeNull(types.string),
  active: types.maybeNull(types.boolean),
  description: types.maybeNull(types.string),
  totalProduct: types.maybeNull(types.number),
});

export type SellerCategory = Instance<typeof SellerCategoryModel>;

export function mapSellerCategoriesResultToSellerCategories(
  category: SellerCategoryResult
): SellerCategory {
  return {
    id: category.id,
    name: category.name,
    icon: category.icon,
    active: category.active,
    description: category.description,
    totalProduct: category.total_product,
  };
}

/**
 * USER MODEL
 */

export const UserModel = types.model("UserModel").props({
  id: types.string,
  username: types.string,
  phone: types.string,
  name: types.string,
  referralCode: types.string,
  verified: types.boolean,
  createdAt: types.maybeNull(types.string),
});

export type User = Instance<typeof UserModel>;

export const CityModel = types.model("CityModel").props({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  stateId: types.maybeNull(types.number),
});

export type City = Instance<typeof CityModel>;

export const SocialModel = types.model("SocialModel", {
  key: types.maybeNull(types.string),
  type: types.maybeNull(types.enumeration(["whatsapp", "instagram", "facebook", "twitter"])),
  value: types.maybeNull(types.string),
});

export const ImageConfigModel = types.model("ImageConfigModel", {
  wide: types.maybeNull(types.boolean),
  data: types.array(types.string),
  links: types.array(types.string),
});

export const VideoConfigModel = types.model("VideoConfigModel", {
  link: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});

export const StoreFrontComponentModel = types.model("StoreFrontComponentModel", {
  type: types.maybeNull(types.string),
  variant: types.maybeNull(types.string),
  imageConfig: types.maybeNull(ImageConfigModel),
  videoConfig: types.maybeNull(VideoConfigModel),
  active: types.maybeNull(types.boolean),
});

/**
 * SellerInfo MODEL
 */

export const SellerInfoModel = types.model("SellerInfoModel").props({
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  profilePicture: types.maybeNull(types.string),
  socials: types.array(SocialModel),
});

export const AppearanceModel = types.model("AppearanceModel").props({
  mainBgColor: types.maybeNull(types.string),
  mainColor: types.maybeNull(types.string),
  mainTextColor: types.maybeNull(types.string),
})

/**
 * Landing MODEL
 */

export const LandingModel = types.model("LandingModel").props({
  sellerInfo: types.maybeNull(SellerInfoModel),
  components: types.array(StoreFrontComponentModel),
  appearanceSetting: types.maybeNull(AppearanceModel),
});

/**
 * SELLER PROFILE MODEL
 */

export const SellerProfileModel = types.model("SellerProfileModel").props({
  id: types.string,
  address: types.string,
  additionalAddress: types.string,
  profilePicture: types.string,
  zipcode: types.string,
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  categoryId: types.maybeNull(types.string),
  deliveryArea: types.maybeNull(types.string),
  deliveryType: types.maybeNull(types.string),
  sellerJopan: types.boolean,
  digitalPayments: types.boolean,
  supplier: types.boolean,
  featuredWholesaler: types.boolean,
  reseller: types.boolean,
  isReseller: types.boolean,
  isTrading: types.boolean,
  isSupplier: types.boolean,
  isExternal: types.boolean,
  isCrediStore: types.boolean,
  User: types.maybeNull(UserModel),
  City: types.maybeNull(CityModel),
  State: types.maybe(types.string),
  District: types.maybe(types.string),
  Subdistrict: types.maybe(types.string),
  countOrders: types.number,
  countProducts: types.number,
  resellerMinOrderTempoLimit: types.number,
  resellerMaxOrderTempoLimit: types.number,
  resellerMinOrderCashLimit: types.number,
  tradingMinOrderTempoLimit: types.number,
  tradingMaxOrderTempoLimit: types.number,
  tradingMinOrderCashLimit: types.number,
  minOrderCashLimit: types.number,
  maxOrderCashLimit: types.number,
  minOrderTempoLimit: types.number,
  maxOrderTempoLimit: types.number,
  isDomainActive: types.boolean,
  landing_setting: types.maybeNull(LandingModel),
});

export type SellerProfile = Instance<typeof SellerProfileModel>;

export function mapSellerProfileResultToSellerProfile(sellerProfile: GetSellerProfileResult): SellerProfile {
  const { profile } = sellerProfile;
  if (profile) {
    const {
      seller_jopan,
      digital_payments,
      supplier,
      featured_wholesaler,
      reseller,
    } = profile;
    return {
      id: profile.id,
      address: profile.address,
      additionalAddress: profile.additional_address,
      profilePicture: profile.profile_picture,
      zipcode: profile.zipcode,
      latitude: profile.latitude,
      longitude: profile.longitude,
      categoryId: profile.category_id,
      deliveryArea: profile.delivery_area,
      deliveryType: profile.delivery_type ?? "delivery", // default
      sellerJopan: seller_jopan,
      digitalPayments: digital_payments,
      supplier,
      featuredWholesaler: featured_wholesaler,
      reseller,
      isReseller:
        seller_jopan &&
        digital_payments &&
        !supplier &&
        !featured_wholesaler &&
        reseller,
      isTrading:
        seller_jopan &&
        digital_payments &&
        !supplier &&
        featured_wholesaler &&
        !reseller,
      isSupplier:
        seller_jopan &&
        !digital_payments &&
        supplier &&
        !featured_wholesaler &&
        !reseller,
      isExternal:
        seller_jopan &&
        !digital_payments &&
        !supplier &&
        !featured_wholesaler &&
        !reseller,
      isCrediStore:
        !seller_jopan &&
        !digital_payments &&
        !supplier &&
        !featured_wholesaler &&
        !reseller,
      User: profile.User
        ? {
          id: profile.User.id,
          username: profile.User.username,
          phone: profile.User.phone,
          name: profile.User.name,
          referralCode: profile.User.referral_code,
          verified: profile.User.verified,
          createdAt: profile.User.created_at,
        }
        : null,
      City: profile.City
        ? {
          id: profile.City.id,
          name: profile.City.name,
          stateId: profile.City.state_id ?? null,
        }
        : null,
      State: profile.State,
      District: profile.District,
      Subdistrict: profile.Subdistrict,
      countOrders: profile.count_orders ?? null,
      countProducts: profile.count_products || null,
      resellerMinOrderTempoLimit:
        profile.reseller_min_order_tempo_limit || null,
      resellerMaxOrderTempoLimit:
        profile.reseller_max_order_tempo_limit || null,
      resellerMinOrderCashLimit: profile.reseller_min_order_cash_limit || null,
      tradingMinOrderTempoLimit: profile.trading_min_order_tempo_limit || null,
      tradingMaxOrderTempoLimit: profile.trading_max_order_tempo_limit || null,
      tradingMinOrderCashLimit: profile.trading_min_order_cash_limit || null,
      minOrderCashLimit: profile.min_order_cash_limit || null,
      maxOrderCashLimit: profile.max_order_cash_limit || null,
      minOrderTempoLimit: profile.min_order_tempo_limit || null,
      maxOrderTempoLimit: profile.max_order_tempo_limit || null,
      isDomainActive: profile.is_domain_active ?? true,
      landing_setting: profile.landing_setting
        ? {
          sellerInfo: {
            name: profile.landing_setting.sellerInfo.name || null,
            description: profile.landing_setting.sellerInfo.description || null,
            profilePicture: profile.landing_setting.sellerInfo.profilePicture || null,
            socials: cast(profile.landing_setting.sellerInfo.socials) || null
          },
          components: cast(profile.landing_setting.components),
          appearanceSetting: cast(profile.landing_setting.appearanceSetting),
        }
        : null
    };
  }
  return null;
}

interface FeaturedWholesalersResponse {
  id: string;
  delivery_area: string;
  User: {
    name: string;
    username: string;
  };
  City: {
    name?: string;
  };
}

export interface FeaturedWholesaler {
  id: string;
  deliveryArea: string;
  storeName: string;
  slug: string;
  city: string;
}

export function mapFeaturedWholesalesResponseToFeaturedWholesalers(
  response: FeaturedWholesalersResponse[]
): FeaturedWholesaler[] {
  return response?.map((wholesaler: FeaturedWholesalersResponse) => ({
    id: wholesaler.id,
    deliveryArea: wholesaler.delivery_area,
    storeName: wholesaler.User?.name,
    slug: wholesaler.User?.username,
    city: wholesaler.City?.name ?? null,
  }));
}

export const SellerAdminFeeModel = types.model("SellerAdminFeeModel").props({
  id: types.string,
  type: types.enumeration<AdminFeeType>(
    "AdminFeeType",
    Object.values(AdminFeeType)
  ),
  percentageAmount: types.number,
  dueDays: types.number,
  amount: types.number,
  active: types.boolean,
});

export type SellerAdminFee = Instance<typeof SellerAdminFeeModel>;

export function mapAdminFeeResponseToAdminFee(
  response: AdminFeeResponse
): SellerAdminFee {
  return {
    id: response.id,
    type:
      response.type === AdminFeeType.cash
        ? AdminFeeType.cash
        : AdminFeeType.tempo,
    percentageAmount: response.percentage_amount,
    dueDays: response.due_days,
    amount: response.amount,
    active: response.active,
  };
}

export const DeliverymanModel = types.model("Deliveryman").props({
  id: types.identifier,
  username: types.string,
});

export type Deliveryman = Instance<typeof DeliverymanModel>;

export const SalesModel = types.model("Sales").props({
  id: types.identifier,
  username: types.string,
});

export type Sales = Instance<typeof SalesModel>;

export const DeliveryFeeModel = types.model("DeliveryFee").props({
  id: types.string,
  amount: types.number,
  active: types.boolean,
  type: types.string,
  seller_type: types.string,
});

export type DeliveryFee = Instance<typeof DeliveryFeeModel>;

export function mapDeliveryFeeObjectToDeliveryFee(
  obj: DeliveryFeeObject[]
): DeliveryFee[] {
  return obj.map((result) => {
    return {
      id: result.id,
      amount: result.amount,
      active: result.active,
      type: result.type,
      seller_type: result.seller_type,
    };
  });
}
